import logo from './logo.svg';
import './App.css';
import './Font.css'
import branding from './branding.gif'
function App() {
	return (
		<div className="App">
			<header className="App-header">
				<img src={branding} className='branding'></img>
				<span className='poppins-regular name animate__animated animate__bounceInLeft'>glenn nerrie</span><br></br>
				<span className='poppins-light message animate__animated animate__bounceInRight'>is still building this site</span>

				<span className='footer poppins-regular animate__animated animate__bounceInUp  animate__delay-1s '>coming soon</span>
			</header>
		</div>
	);
}

export default App;
